import { Auth0Client } from '@auth0/auth0-spa-js';
import { authRequest } from '@swo/auth-utils';
import { UserSettings, UserSettingsFn } from '@swo/user-settings-web';
import axios from 'axios';
import { Lock } from 'lock';
import { LoggerFactory } from '../logger';

type UserSettingsDto = {
  settings: string;
}

const GET_LOCK_KEY = 'GET_USER_SETTINGS_LOCK';
const SET_LOCK_KEY = 'SET_USER_SETTINGS_LOCK';

let SETTINGS: { [appName: string]: UserSettings };

export function setupGetUserSettings(auth0Client: Auth0Client, userSettingsApiUrl: string, loggerFn: LoggerFactory): UserSettingsFn {
  const logger = loggerFn('User Settings');

  const lock = Lock();

  return appName => {
    const getSettings = () => new Promise<UserSettings>((resolve) => {
      lock(GET_LOCK_KEY, async (createReleaser) => {
        const release = createReleaser();
        // TODO for now, we only once download data from API and then serve from Local Storage
        // TODO next step is to check if there are any changes by calling the CF Worker
        if (!SETTINGS) {
          try {
            const apiData = await getAPIData(auth0Client, userSettingsApiUrl);
            SETTINGS = apiData.settings ? JSON.parse(apiData.settings) : {};
          } catch (e) {
            logger.error('Unable to get user settings for:', appName, '. Reason: ', e);
            SETTINGS = {};
          }
        }

        resolve(SETTINGS[appName] || {});
        release();
      });
    });

    const saveSettings = async (settings: UserSettings) => {
      await getSettings();
      await new Promise<void>((resolve, reject) => {
        lock(SET_LOCK_KEY, async (createReleaser) => {
          const release = createReleaser();
          const clone = {
            ...SETTINGS,
            [appName]: {
              ...SETTINGS[appName],
              ...settings
            }
          };
          try {
            await setAPIData(auth0Client, userSettingsApiUrl, JSON.stringify(clone));
            SETTINGS = clone;
            resolve();
          } catch (e) {
            logger.error('Unable to set user settings for app:', appName, e);
            reject(e);
          } finally {
            release();
          }
        });
      });
    };

    return {
      getSettings,
      saveSettings
    };
  };
}

async function getAPIData(auth0Client: Auth0Client, url: string): Promise<UserSettingsDto> {
  const { data } = await authRequest<UserSettingsDto>({
    loginWithRedirect: auth0Client.loginWithRedirect.bind(auth0Client),
    getTokenSilently: auth0Client.getTokenSilently.bind(auth0Client),
    requestConfig: {
      url
    },
    instance: axios
  });
  return data;
}

async function setAPIData(auth0Client: Auth0Client, url: string, serializedValue: string): Promise<void> {
  await authRequest<void>({
    loginWithRedirect: auth0Client.loginWithRedirect.bind(auth0Client),
    getTokenSilently: auth0Client.getTokenSilently.bind(auth0Client),
    requestConfig: {
      url,
      method: 'post',
      data: JSON.stringify(serializedValue),
      headers: {
        'Content-Type': 'application/json'
      }
    },
    instance: axios
  });
}
