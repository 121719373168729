import { start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import { getDynamicTemplate } from "./dynamic-template";
import { createSWOAppRegistrator } from "./swo-app";
import { getShellProps } from "./shell-props";
import { getAppsData } from './app-data';
import { createAutoLogin } from './auth';
import { tryRunHeap } from './heap';
import { createLoggerFactory } from './logger';
import setupServiceWorker from './service-worker';
import { trackWebVitals } from './tracking';
import { tryRunInMoment } from "./inmoment";
import { tryRunSentry } from "./sentry";
import './main.scss'
import { runProductConfig } from './product-config';

require(`${__LAYOUT_DIR__}/layout.scss`);

(async () => {
    const getLogger = createLoggerFactory(__LOG_LEVEL__);
    await runProductConfig(getLogger);
    tryRunSentry(__SENTRY_DSN__, __ENV__, getLogger);
    setupServiceWorker(__SERVICE_WORKER__, getLogger);
    tryRunHeap(__HEAP_ID__, getLogger);
    tryRunInMoment(__INMOMENT_TOKEN__, __INMOMENT_START_IMMEDIATELY__, __INMOMENT_NO_COOKIE__, __ENV__, getLogger);

    const [appsData, shellProps] = await Promise.all([getAppsData(), getShellProps(getLogger)]);
    const registerSWOApp = createSWOAppRegistrator(shellProps);

    const layoutTemplate = getDynamicTemplate(appsData);

    const routes = constructRoutes(layoutTemplate);

    const autoLogin = createAutoLogin(appsData, shellProps.auth0Client, getLogger);

    const applications = constructApplications({
        routes,
        loadApp: async ({name}) => {
            await autoLogin(name);
            return System.import(name);
        }
    });

    const layoutEngine = constructLayoutEngine({routes, applications});

    trackWebVitals(shellProps.applicationInsights);

    applications.forEach(registerSWOApp);
    layoutEngine.activate();
    start();
})();
