import { LoggerFactory } from '../logger';
import { Workbox } from 'workbox-window';

export default function setup(isEnabled: boolean, getLogger: LoggerFactory): void {
    const logger = getLogger('Service Worker');

    if (!isEnabled) {
        logger.info('Disabled in configuration.');
        return;
    }

    if (!navigator.serviceWorker) {
        logger.info('Not supported by the browser!');
        return;
    }

    const wb = new Workbox('/sw.js');

    wb.addEventListener('waiting', () => {
        wb.messageSkipWaiting();
        logger.info('Skip waiting.');
    });

    logger.info('Starting...');

    wb.register();
    logger.info('Registered.');
}
