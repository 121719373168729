import { AppLoadProbe } from '@swo/tracking-web';
import { IApplicationInsights } from "@microsoft/applicationinsights-web";
import { LoggerFactory } from '../logger';
import { isSentryEnabled } from "../sentry";

import { createAppLoadProbe as createAIAppLoadProbe } from "./app-load-ai"
import { createAppLoadProbe as createSentryAppLoadProbe } from "./app-load-sentry"

export function createAppLoadProbe(appInsights: IApplicationInsights, getLogger: LoggerFactory): AppLoadProbe {
    const aiProbe = createAIAppLoadProbe(appInsights, getLogger);

    if (!isSentryEnabled())
        return aiProbe;

    const sentryProbe = createSentryAppLoadProbe(getLogger);

    return (appName: string, teamNameOrProps: any, props) => {
        aiProbe(appName, teamNameOrProps, props);
        sentryProbe(appName)
    };
}