import { LoggerFactory } from '../logger';

export async function runProductConfig(getLogger: LoggerFactory,) {
    const logger = getLogger('Product Config');
    logger.info('Trying to run product config scripts');

    const module = await import(`./..${ __LAYOUT_DIR__.replace(/"|\./ig, '') }/swo-product-config`);
    logger.info('Running custom scripts');
    await module.default(logger);
    logger.info('Finished');
}
