import { SingleSpaCustomEventDetail } from 'single-spa';
import { startTransaction } from "@sentry/browser";
import { Transaction } from "@sentry/tracing";
import { LoggerFactory } from "../logger";

export type AppLoadProbe = (appName: string) => void

export function createAppLoadProbe(getLogger: LoggerFactory): AppLoadProbe {
    const logger = getLogger('Sentry');

    let transactions: { [appName: string]: Transaction };

    window.addEventListener('single-spa:before-app-change', (evt: any) => {
        const { appsByNewStatus } = (evt.detail as SingleSpaCustomEventDetail);

        const newApps = appsByNewStatus.MOUNTED;

        if (!newApps || !newApps.length)
            return;

        logger.info('Transactions created', newApps);
        transactions = newApps.reduce((all, appName) => ({...all, ...{[appName]: startTransaction({ name: `MicroApp load: ${appName}` })}}), transactions);
    });

    return (appName: string) => {
        const transaction = transactions[appName];

        if (!transaction) {
            logger.error(`Transactions for ${appName} does not exist`);
            return;
        }

        transaction.finish();

        delete transactions[appName];
    };
}