import { onUserData } from "@swo/user-web";
import { Logger } from "loglevel";
import { LoggerFactory } from '../logger';
import { isBot } from "../is-bot";

const softwareOneEmails = ['@softwareone.', '@comparex.']

type HeapWindow = Window & {heap: any}

// Heap Runner
function runHeap(window: HeapWindow, applicationId: string, logger: Logger): void {
    if (!window.heap) {
        window.heap = [];
        window.heap.load=function(e,t){
            window.heap.appid=e;
            window.heap.config=t=t||{};
            var r = document.createElement("script");
            r.type="text/javascript";
            r.async=!0;
            r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
            
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(r,a);
            
            for(var n = function(e){
                return function(){
                    window.heap.push([e].concat(Array.prototype.slice.call(arguments,0)))
                }
            },
            p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],
            o=0;
            o<p.length;o++)
                window.heap[p[o]]=n(p[o])
        };
        
        window.heap.load(applicationId, {
            secureCookie: true
        });
        
        window.heap.clearEventProperties();
    }

    onUserData(userData => {
        logger.debug('Reporting user data.', userData);

        window.heap.identify(userData.userId);
        
        const lowerCaseEmail = userData.email.toLocaleLowerCase();
        
        window.heap.addUserProperties({
            IsSoftwareOneUser: softwareOneEmails.some(x => lowerCaseEmail.includes(x)),
        });

        window.heap.addEventProperties({
            SessionId: userData.sessionId,
            TenantId: userData.tenantId,
            TenantName: userData.tenantName,
            IsSilentLogin: userData.isImpersonated,
        });
    }, true);
}

export function tryRunHeap(applicationId: string, getLogger: LoggerFactory): void {
    const logger = getLogger('Heap');

    if (!applicationId) {
        logger.info('Application id not set. Skipping heap run.');
        return;
    }


    if (isBot()) {
        logger.info(`Heap disabled for user agent`);
        logger.info(`Skipping heap run.`);
        return;
    }

    if (!applicationId) {
        logger.info('Application id not set. Skipping heap run.');
        return;
    }

    logger.info(`Staring with appId: ${applicationId}.`);
    runHeap(window as any, applicationId, logger);
}
