import { AppLoadProbe } from '@swo/tracking-web';
import { IApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { LoggerFactory } from '../logger';
import { SingleSpaCustomEventDetail } from 'single-spa';
import { customProperties } from './custom-properties';
import { isTrackingEnabled } from "./is-enabled";


export function createAppLoadProbe(appInsights: IApplicationInsights, getLogger: LoggerFactory): AppLoadProbe {
    const logger = getLogger('App Load')

    if (!isTrackingEnabled())
        return () => logger.info('Performance measurements disabled');

    let startTimes: { [appName: string]: number };

    window.addEventListener('single-spa:before-app-change', (evt: any) => {
        const { appsByNewStatus } = (evt.detail as SingleSpaCustomEventDetail);

        const newApps = appsByNewStatus.MOUNTED;

        if (!newApps?.length)
            return;

        const now = Date.now();

        logger.info('Measurement started', newApps);
        startTimes = newApps.reduce((all, appName) => ({...all, ...{[appName]: now}}), startTimes)
    });

    return (appName: string, teamNameOrProps: string | ICustomProperties | undefined, props) => {
        // Proper version
        function logWithTeamName(teamName: string): void {
            appInsights.trackMetric({
                name: `AppLoad: ${appName}`,
                average
            }, {...props, ...customProperties('AppLoad'), MicroApp: appName, Team: teamName});
        }

        // Legacy version to remove when possible
        function logWithoutName(props?: ICustomProperties): void {
            const msg = '%c Please update to: https://softwareone-pc.visualstudio.com/DefaultCollection/PyraCloud/_git/swo-libs?path=/libs/tracking/README.md&_a=preview';
            const css = 'background-color: black; color: yellow; width: 100%; font-size: 14px';
            console.log(msg, css);

            appInsights.trackMetric({
                name: `AppLoad: ${appName}`,
                average
            }, {...props, ...customProperties('AppLoad'), MicroApp: appName});
        }


        const start = startTimes && startTimes[appName];
        if (!start) {
            logger.error(`Measurement for ${appName} not started`);
            return;
        }

        const average = Math.ceil(Date.now() - start);

        logger.info(`Measurement for ${appName} finished: ${average}ms`);

        if (typeof teamNameOrProps === 'string' || teamNameOrProps instanceof String) {
            logWithTeamName(teamNameOrProps as string);
        } else {
            logWithoutName(teamNameOrProps as ICustomProperties | undefined);
        }

        delete startTimes[appName];
    };
}