import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { LoggerFactory } from "../logger";
import { isBot } from "../is-bot";


let isEnabled = false;

const ALLOWED_OVERRIDES = ['import-map-override:react', 'import-map-override:single-spa', 'import-map-override:react-dom'];

function hasOverrides(): boolean {
  const overrides = Object.keys(localStorage).filter(k => k.startsWith('import-map-override:') && ALLOWED_OVERRIDES.indexOf(k) === -1);
  const disabled = JSON.parse(localStorage.getItem('import-map-overrides-disabled') || '[]').filter(k => ALLOWED_OVERRIDES.indexOf(k) === -1);

  return overrides.length - disabled.length > 0;
}


export function isSentryEnabled(): boolean {
  return isEnabled;
}

export function tryRunSentry(dsn: string, environment: string, getLogger: LoggerFactory): void {
  const logger = getLogger('Sentry');

  if (!dsn) {
    logger.warn('Sentry DSN not set. Skipping Sentry.');
    return;
  }

  if (hasOverrides()) {
    logger.warn('Custom import maps detected. Assuming local development. Skipping Sentry.');
    return;
  }

  if (isBot()) {
    logger.warn(`Sentry disabled for user agent`);
    return;
  }

  isEnabled = true;

  Sentry.init({
    dsn,
    environment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}