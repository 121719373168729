import { getLogger, Logger, LogLevelNumbers } from 'loglevel';


export type LoggerFactory = (name: string) => Logger;


export function createLoggerFactory(defaultLevel: LogLevelNumbers): LoggerFactory {
    let customLevel: LogLevelNumbers;
    const loggers = new Map<string, Logger>();

    window['setLogLevel'] = (level: LogLevelNumbers) => {
        customLevel = level;
        loggers.forEach((logger, name) => {
            console.log(`[${name}] Setting log level to ${customLevel}.`); // Native console.log here since logging can be muted.
            logger.setLevel(customLevel, true);
        });
    };

    const createLogger = (name: string) => {
        if (loggers.has(name))
            return loggers.get(name);

        const logger = getLogger(name);

        loggers.set(name, logger);

        const originalFactory = logger.methodFactory;
        logger.methodFactory = (methodName, level) => {
            const rawMethod = originalFactory(methodName, level, name);
            return (...msg: any[]) => rawMethod(...[`[${name}]`, ...msg]);
        };

        const startLevel = !!customLevel || customLevel === 0 ? customLevel : defaultLevel;
        logger.setDefaultLevel(startLevel);
        logger.setLevel(logger.getLevel()); // According to methodFactory documentation :|

        return logger;
    }

    const shellLogger = createLogger('Shell');
    shellLogger.log(`Logging level set to ${shellLogger.getLevel()}. To change it use window.setLogLevel([0-5])`);

    return name => createLogger(name);
}
