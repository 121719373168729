import { AppsData } from './app-data';
import { getRoutes } from "./routes";

const getAppDataWithDefaults = (appsData: AppsData) =>
    Object.keys(appsData).reduce((previousValue, currentValue) => {
        if (__DEFAULT_WRAPPER_CLASS__) {
            appsData[currentValue].wrapperClass ??= __DEFAULT_WRAPPER_CLASS__;
        }
        return {...previousValue, [currentValue]: appsData[currentValue]}
    }, {})

function getDynamicRoutesTemplate(appsData: AppsData): string {
    const routes = getRoutes(appsData);

    return routes.map(route =>
        `<route path="${route.path}">${route.apps.map(app => generateWrappedApplicationTag(appsData, app.name)).join('')}</route>`
    ).join('');
}

function generateWrappedApplicationTag(appsData: AppsData, appName: string): string {
    const application = `<application name="${appName}"></application>`;
    if (!appsData?.[appName]?.wrapperClass) {
        return application;
    }
    return `<div class="${appsData[appName].wrapperClass}">${application}</div>`
}

export function getDynamicTemplate(appsData: AppsData): string {
    const appsDataWithDefaults = getAppDataWithDefaults(appsData);
    const dynamicRoutesTemplate = getDynamicRoutesTemplate(appsDataWithDefaults);
    const applicationTagReg = /<application[^>]*?name=(["\'])?((?:.(?!\1|>))*.?)\1?[^>]*><\/application>/gm;
    return require(`${__LAYOUT_DIR__}/layout.html`)
        .replace(applicationTagReg, (...args) => generateWrappedApplicationTag(appsDataWithDefaults, args[2]))
        .replace('<!-- DYNAMIC ROUTES -->', dynamicRoutesTemplate)
}
