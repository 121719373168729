import { Auth0Client } from '@auth0/auth0-spa-js';
import { IApplicationInsights } from '@microsoft/applicationinsights-web';
import { ErrorNavigator } from '@swo/error-navigator';
import { AppLoadProbe } from '@swo/tracking-web';
import { UserSettingsFn } from '@swo/user-settings-web';
import { GetRolesFn, GetUserDataFn, setupRoles, setupUserData } from '@swo/user-web';
import { GetClaimsFn, setupClaims } from "@swo/claims-web";
import { createAuth0Client } from './auth';
import { setupErrorNavigator } from './error-navigation';
import { LoggerFactory } from './logger';
import { createApplicationInsights, createAppLoadProbe } from './tracking';
import { setupGetUserSettings } from './user-settings';
import i18nSync, { I18nSync } from './i18next/sync';

export interface ShellProps {
  appLoadProbe: AppLoadProbe
  auth0Client: Auth0Client;
  getUserData: GetUserDataFn;
  getRoles: GetRolesFn;
  applicationInsights: IApplicationInsights;
  getLogger: LoggerFactory;
  errorNavigator: ErrorNavigator;
  getAppUserSettings: UserSettingsFn;
  getClaims?: GetClaimsFn;
  i18nSync: I18nSync;
}

export async function getShellProps(getLogger: LoggerFactory): Promise<ShellProps> {
  const applicationInsights = createApplicationInsights(getLogger);

  const auth0Client = await createAuth0Client(applicationInsights, getLogger) as any;

  const props: ShellProps = {
    auth0Client,
    applicationInsights,
    appLoadProbe: createAppLoadProbe(applicationInsights, getLogger),
    getUserData: setupUserData(auth0Client, __TENANTS_URL__),
    getRoles: setupRoles(auth0Client, __ROLES_URL__),
    getLogger,
    errorNavigator: setupErrorNavigator(getLogger),
    getAppUserSettings: setupGetUserSettings(auth0Client, __USER_SETTINGS_URL__, getLogger),
    i18nSync
  }

  if(__CLAIMS_URL__) {
    props.getClaims = setupClaims(auth0Client, {
      url: __CLAIMS_URL__
    });
  }
  return props;
}
