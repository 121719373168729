import {
    ApplicationInsights,
    IApplicationInsights,
    ICustomProperties,
    IMetricTelemetry,
    IPageViewTelemetry
} from '@microsoft/applicationinsights-web'
import { Snippet } from '@microsoft/applicationinsights-web';
import { Logger } from 'loglevel';
import { LoggerFactory } from '../logger';

class SWOApplicationInsights extends ApplicationInsights {
    constructor(snippet: Snippet,
                private logger: Logger) {
        super(snippet);
    }

    trackMetric(metric: IMetricTelemetry, customProperties?: ICustomProperties) {
        this.logger.info(`Reporting metric ${metric.name}: ${metric.average}ms`, customProperties || '');
        super.trackMetric(metric, customProperties);
    }

    trackPageView(pageView?: IPageViewTelemetry) {
        this.logger.info(`Reporting page view`, pageView.properties || '');
        super.trackPageView(pageView);
    }
}

export function createApplicationInsights(getLogger: LoggerFactory): IApplicationInsights {
    const logger = getLogger('Application Insights');
    const appInsights = new SWOApplicationInsights({ config: { instrumentationKey: __APP_INSIGHTS_KEY__ } }, logger);

    appInsights.loadAppInsights();

    return appInsights;
}
