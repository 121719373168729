import { AppsData } from './app-data';


export type Route = {
    path: string;
    apps: { name: string }[]
}


export function getRoutes(appsData: AppsData): Route[] {
    return Object.keys(appsData).reduce((all, name) => {
        const appData = appsData[name];
        if (!appData.appRoute)
            return all;

        const appRoutes = Array.isArray(appData.appRoute) ? appData.appRoute : [appData.appRoute];
        appRoutes.forEach(appRoute => {
            let route = all.find(v => v.path === appRoute);
            if (!route) {
                route = {type: "route", path: appRoute, apps: []};
                all.push(route);
            }

            route.apps.push({type: 'application', name});
        });

        return all;
    }, []);
}


