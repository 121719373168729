import { LoggerFactory } from "../logger";
import { onUserData } from "@swo/user-web";
import isBot from "isbot";

// InMoment supported languages
type Language = 'AR' | 'BE' | 'BG' | 'ZH_HANS' | 'ZH_HANT' | 'CZ' | 'DA' | 'NL' | 'NL_NL_X_INFORMAL' | 'EN' | 'ET' | 'FI' | 'FR' | 'DE' | 'DE_DE_X_INFORMAL' | 'EL' | 'HU' | 'ID' | 'IT' | 'JA' | 'KO' | 'LT' | 'LV' | 'MS' | 'NO' | 'PL' | 'PT' | 'PT_PT' | 'RO' | 'RU' | 'SL' | 'ES' | 'ES_MX' | 'SV' | 'TH' | 'TR' | 'UK' | 'VI';

function runInMoment(accountToken: string,
                     language: Language,
                     surveyImmediately: boolean,
                     environment: string,
                     noCookie: boolean): void {

  onUserData(userData => {
    (window as any).wootric_survey_immediately = surveyImmediately;
    (window as any).wootric_no_surveyed_cookie = noCookie;

    (window as any).wootricSettings = {
      account_token: accountToken,
      email: userData.email,
      language,
      properties:{
        SessionId: userData.sessionId,
        IsSilentLogin: userData.isImpersonated,
        TenantId: userData.tenantId,
        Identity: userData.userId,
        TenantName: userData.tenantName,
        Environment: environment,
        IsSoftwareOneUser: userData.email.toLocaleLowerCase().indexOf('@softwareone.') > -1
      }
    };

    (window as any).wootric('run');
  }, true);
}


export function tryRunInMoment(accountToken: string,
                               surveyImmediately: boolean,
                               noCookie: boolean,
                               environment: string,
                               getLogger: LoggerFactory): void {
  const logger = getLogger('InMoment');
  const { userAgent } = navigator;

  if (!accountToken) {
    logger.info('Token not set. Skipping InMoment.');
    return;
  }

  if (isBot(userAgent) || userAgent === 'Testzilla') {
    logger.info(`InMoment disabled for user agent ${userAgent}`);
    return;
  }

  runInMoment(accountToken, 'EN', surveyImmediately, environment, noCookie);
}