import { getFID, getLCP } from 'web-vitals';
import { IApplicationInsights } from '@microsoft/applicationinsights-web'
import { customProperties } from './custom-properties';
import { isTrackingEnabled } from "./is-enabled";


export function trackWebVitals(appInsights: IApplicationInsights): void {
    if (!isTrackingEnabled())
        return;

    getLCP(metric => appInsights.trackMetric({
        name: `LCP: ${document.title}`,
        average: Math.ceil(metric.value)
    }, customProperties('LCP')), true);

    getFID(metric => appInsights.trackMetric({
        name: `FID: ${document.title}`,
        average: Math.ceil(metric.value)
    }, customProperties('FID')), true);
}
